<template>
  <div class="welcome d-flex align-center justify-center py-6">
    <div class="welcome__container d-flex flex-column">
      <img
        class="welcome__illustration"
        :src="$static.illustration"
        :alt="$t('welcomeContent.createPermissions.title')">

      <template v-if="hasCreatePermissions">
        <h3 class="mb-1">
          {{ $t('welcomeContent.createPermissions.title') }}
        </h3>
        <p class="mb-0">
          {{ $t('welcomeContent.createPermissions.p1') }}
        </p>
        <p class="mb-8">
          {{ $t('welcomeContent.createPermissions.p2') }}
          <a
            :href="$docLinks.cloudCostManagement"
            class="cy-link"
            target="_blank">
            {{ $t('learnMore') }}
          </a>
        </p>
        <CyButton
          icon="add"
          theme="secondary"
          variant="primary"
          :to="{ name: 'cloudCostManagementAccountsCredentials' }">
          {{ $t('addAccount') }}
        </CyButton>
      </template>

      <template v-else>
        <h3 class="mb-1">
          {{ $t('welcomeContent.noCreatePermissions.title') }}
        </h3>

        <p class="mb-0">
          {{ $t('welcomeContent.noCreatePermissions.p') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import { ccm } from '@/assets/images'

export default {
  name: 'CyPageCloudCostManagementWelcome',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementWelcome'), [
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagementSection'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementSection'),
      description: {
        text: this.$t('routes.cloudCostManagementSectionDescription'),
      },
    }
  },
  computed: {
    $static: () => ({
      illustration: ccm.illustration,
    }),
    hasCreatePermissions () {
      return this.$cycloid.permissions.canDisplay('CreateCloudCostManagementAccount')
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementWelcome',
        addAccount: 'Add account',
        welcomeContent: {
          createPermissions: {
            p1: 'Cloud Cost Management helps you visualize, understand and control your costs.',
            p2: 'Get an overview of your expenses across multiple cloud providers, or track your spending closer with fine-grained filtering.',
            title: 'Welcome to the Cloud Cost Management',
          },
          noCreatePermissions: {
            p: 'To access cloud cost data, an account must first be created by a member with sufficient permissions.',
            title: 'No Cloud Cost Management accounts',
          },
        },
      },
      es: {
        title: '@:routes.cloudCostManagementWelcome',
        addAccount: 'Añadir cuenta',
        welcomeContent: {
          createPermissions: {
            p1: 'El Cloud Cost Management le ayuda a visualizar, comprender y controlar sus costos.',
            p2: 'Obtenga una descripción general de sus gastos en varios proveedores de la nube o realice un seguimiento más detallado de sus gastos con un filtrado detallado.',
            title: 'Bienvenido al Cloud Cost Management',
          },
          noCreatePermissions: {
            p: 'Para acceder a los datos de costos de la nube, primero debe crear una cuenta un miembro con suficientes permisos.',
            title: 'Sin cuentas de Cloud Cost Management',
          },
        },
      },
      fr: {
        title: '@:routes.cloudCostManagementWelcome',
        addAccount: 'Ajouter un compte',
        welcomeContent: {
          createPermissions: {
            p1: 'Cloud Cost Management vous aide à visualiser, comprendre et contrôler vos coûts.',
            p2: 'Obtenez un aperçu de vos dépenses sur plusieurs fournisseurs de cloud, ou suivez vos dépenses de plus près grâce à un filtrage précis.',
            title: 'Bienvenue dans le Cloud Cost Management',
          },
          noCreatePermissions: {
            p: `Pour accéder aux données de coût du cloud, un compte doit d'abord être créé par un membre disposant d'autorisations suffisantes.`,
            title: 'Aucun compte Cloud Cost Management',
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome {
  &__container {
    max-width: 500px;

    h3,
    p {
      text-align: center;
    }

    .cy-btn {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__illustration {
    margin-top: -64px;
    margin-bottom: -32px;
  }
}
</style>
